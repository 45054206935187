<template>
  <!-- 酒店列表的条目 -->
  <v-virtual-scroll
    bench="4"
    ref="virtual"
    :items="list"
    :item-height="120"
    height="calc(100vh - 110px)"
    @scroll.native="scroll"
  >
    <template v-slot="{ item }">
      <van-row type="flex" justify="center" class="item-cont hotel-item">
        <van-row
          @click="chooseTicket(item)"
          class="item-top"
          type="flex"
          justify="space-between"
          align="center"
        >
          <van-col span="6" style="overflow:hidden">
            <van-image :error-icon="error_img" :src="item.hotelPic" height="110" width="100%" />
          </van-col>
          <van-col span="12" class="info">
            <p class="list-title van-ellipsis">{{item.chineseName}}</p>
            <p class="list-text van-ellipsis" v-if="item.tel">联系电话：{{item.tel}}</p>
            <p class="list-text" v-if="item.distance">距目的地：{{item.distance}}公里</p>
            <p class="list-text van-ellipsis max-text">详细地址：{{item.address}}</p>
          </van-col>
          <van-col span="6" style="padding-bottom:1rem">
            <van-row>
              <span style="color: red;">￥</span>
              <span style="color: red;font-size: 1.2rem">{{item.price||'- '}}</span>
              <span class="info-text">起</span>
            </van-row>
            <!--服务商标签-->
            <van-row type="flex" justify="center">
              <Logo :corp="item.corp" />
            </van-row>
          </van-col>
        </van-row>
      </van-row>
      <!-- <small v-if="index+1==list.length" class="text-sm">{{loading?'正在查找，请稍后...':'到底了...'}}</small> -->
    </template>
  </v-virtual-scroll>
</template>

<script>
import {VVirtualScroll} from "vuetify/lib";
import Logo from "@/components/Logo";
export default {
  name: "HotelTicketList",
  props: {
    list: Array, // 列表
    page: Number, // 列表到多少页了
    load: Boolean, // 是否在加载
  },
  data() {
    return {
      error_img: require("../../../assets/NullHotel.png"),
    };
  },
  mounted(){this.scroll()}, 
  components: { VVirtualScroll, Logo },
  methods: {
    //❤️❤️核心：选好票啦~！❤️❤️
    chooseTicket(ticketInfo) {
      this.$store.dispatch("hotel/setHotelTicket", ticketInfo);
      this.$emit('chooseTicket')
    },

    // 滚动监听 快到底加载更多
    scroll(e){
      // 如果前3页没数据  或者  超过20页  或者请求还没完成 就不再请求更多了
      if(this.load||this.page>3&&this.list.length<=this.page||this.page>20) return 
      
      let virtual = this.$refs.virtual
      if(window.innerHeight+virtual.scrollTop+100>=virtual.itemHeight*virtual.items.length)
        this.$emit("load");
    },
  },

  watch: {
    // 加载完成再去检查下是否到底了
    load:{
      handler (newV, oldV) {
        this.scroll()
      },
      deep:true
     }
  }
};
</script>

<style lang="less" scoped>
.virtual-list{
  height: calc(100vh - 114px);
  overflow: auto;
}
.info {
  height: calc(100% - 4px);
  padding: 2px 10px;
  text-align: left;
}
.max-text{
  max-width: 50vw;
}
.item-top {
  padding: 0;
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.item-cont{
  height:110px;
  max-height: 110px;
  background-color: #fff;
}
</style>

<style lang="less">
.hotel-item .van-image__error-icon>.van-icon__image{
  display: block;
  width: 100%;
  height: 100%;
}
</style>