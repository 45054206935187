<template>
  <van-row class="searchTrain">
    <!--头部检索bar-->
    <van-sticky>
      <div class="head-background">
        <van-search @focus="showPop=true" readonly background="rgba(0,0,0,0)" v-model="hotelOrderInfo.keyword"
          show-action placeholder="搜索目的地" @search="onRefresh">
          <template #label>
            <div class="top-city" @click="chooseCity()">
              <div class="deep-blue">{{hotelOrderInfo.city}}</div>
            </div>
          </template>
          <template #left-icon>
            <div style="display:flex">
              <div class="top-date" @click="show_calendar=true">
                <div>
                  入住：
                  <span class="deep-blue">{{hotelOrderInfo.checkInDate|comverTime('MM-DD')}}</span>
                </div>
                <div>
                  离开：
                  <span class="deep-blue">{{hotelOrderInfo.departDate|comverTime('MM-DD')}}</span>
                </div>
              </div>
              <van-icon name="search" class="top-icon" />
            </div>
          </template>
          <template #action>
            <div @click="onRefresh" style="color:#fff">搜索</div>
          </template>
        </van-search>
      </div>
    </van-sticky>

    <!-- 选城市 -->
    <van-popup v-model="showPop" position="right" :style="{ height: '100%',width: '80%' }">
      <HotSpots :show="showPop" @close="showPop=false;onRefresh()" />
    </van-popup>

    <!-- 列表 -->
    <van-skeleton :row-width="skeletonArr" :row="4*7" :loading="listLoad&&!(0 in showList)">
      <TicketList :list="showList" @load="onLoad();listLoad=true;" @chooseTicket="$emit('chooseTicket')" :page="page"
        :load="listLoad" />
      <span v-show="listLoad" class="list-text loading-text">加载中...</span>
      <!-- 20页（单个供应商400-1200个酒店*供应商数量）还是选不出来就不要浪费服务器资源了 -->
      <span class="list-text loading-text" v-if="page>20">没有更多了，换个地点试试吧。</span>
      <van-empty class="loading" v-show="!(0 in showList)" description="暂无酒店" />
    </van-skeleton>

    <!--底部垫底用，对付Tabbar-->
    <van-row style="height: 46px; width:100%"></van-row>

    <!--筛选bar-->
    <van-tabbar style="border:1px solid #eee" v-model="activenav" safe-area-inset-bottom>
      <van-tabbar-item name="money" class="navicon" icon-prefix="icon" icon="feiyongbaohan" replace
        @click="sort=(sort=='PA'?'PD':'PA');sortSelf()">价格</van-tabbar-item>
      <van-tabbar-item @click="sort='DA';sortSelf()" name="time" class="navicon" icon-prefix="icon" icon="neirong"
        replace>距离</van-tabbar-item>
      <van-tabbar-item name="filter" class="navicon" icon-prefix="icon" icon="shaixuan" replace
        :dot="0 in ticket_filter_activeIds" @click="ticket_filter_show = true">筛选</van-tabbar-item>
    </van-tabbar>

    <!--日期选择-->
    <van-calendar v-model="show_calendar" type="range" :show-confirm="false"
      confirm-disabled-text="请选择出发时间"
      :default-date="[new Date(hotelOrderInfo.checkInDate),new Date(hotelOrderInfo.departDate)]" position="right"
      :round="false" @confirm="calendarConfirm" />

    <!--筛选选项卡-->
    <van-action-sheet v-model="ticket_filter_show" title="筛选" safe-area-inset-bottom>
      <h5 class="slider-title">已选价格：{{priceRange|showPriceRange}}</h5>
      <div class="slider-cont">
        <el-slider active-color="#f00" :step="50" :max="1000" v-model="priceRange" range :marks="marks" :show-tooltip="false"></el-slider>
      </div>
      <van-divider />
      <div class="tab_bar_filter_content">
        <van-tree-select height="70vw" :items="ticket_filter_items" :active-id.sync="filter_activeIds"
          :main-active-index.sync="ticket_filter_activeIndex" />
      </div>
      <van-row type="flex" justify="space-around" align="center" style="margin-bottom: 10px">
        <van-button style="width: 100px" plain type="info" @click="ticket_filter_activeIds=[]">清空</van-button>
        <van-button style="width: 100px" type="info" @click="ticket_filter_show = false;onRefresh()">确认</van-button>
      </van-row>
    </van-action-sheet>
    <!-- 遮罩层 -->
    <LoadingCircle :show="hideLoading" />
  </van-row>
</template>

<script>
import Vue from "vue";
import SkeletonArr from "@/common/Skeleton.js";
import { getHotelDistrict } from "@/api/hotel";
import TicketList from "./TicketList";
import { mapGetters } from "vuex";
import { Slider } from "element-ui";
import LoadingCircle from "@/components/LoadingCircle";
import HotSpots from "@/components/HotSpots";
import { searchPosition } from "@/services/hotel";
import { init } from "@/services/list";
import {
  HotelServiceProvider,
  HotelCorpPageSize,
  HotelDefaultSort,
  HotelAsyncList
} from "@/common/config.js";
Vue.use(Slider);

export default {
  components: { TicketList, LoadingCircle, HotSpots },
  computed: {
    ...mapGetters(["hotelOrderInfo"]),
    // 筛选ids  组件默认是多选或全部选项卡单选，改成单个选项卡单选
    filter_activeIds: {
      get() {
        return this.ticket_filter_activeIds;
      },
      set(val) {
        let result = [];
        // 匹配项
        let region = this.ticket_filter_items[2].children.map(e => e.id);
        let star = this.ticket_filter_items[1].children.map(e => e.id);
        let filter = {
          regin: true,
          star: true
        };
        for (let i = val.length - 1; i >= 0; i--) {
          const element = val[i];
          if (region.includes(element)) {
            if (filter.regin) {
              filter.regin = false;
              result.push(element);
            }
          } else if (star.includes(element)) {
            if (filter.star) {
              filter.star = false;
              result.push(element);
            }
          } else {
            result.push(element);
          }
        }
        this.ticket_filter_activeIds = result;
      }
    },
    // 筛选项目，通过ticketList计算得出
    ticket_filter_items: function() {
      let selectList = [];
      // 服务商
      let corparr = new Set(HotelServiceProvider);
      selectList.push({
        text: "服务商",
        children: [...corparr].map(data => {
          return { text: data, id: data };
        })
      });
      // 星级
      selectList.push({
        text: "星级",
        children: [
          { text: "一星 / 普通", id: 1 },
          { text: "二星 / 经济", id: 2 },
          { text: "三星 / 舒适", id: 3 },
          { text: "四星 / 高档", id: 4 },
          { text: "五星 / 豪华", id: 5 }
        ]
      });
      // 地区
      let disarr = [];
      if (this.district)
        this.district.forEach(element => {
          disarr.push({
            text: element.nameCn,
            id: element.nameCn + ":" + element.id
          });
        });
      selectList.push({
        text: "地区",
        children: disarr
      });
      return selectList;
    },
    // 请求参数
    query: function() {
      let localID = "",
        localName = "";
      this.ticket_filter_activeIds.forEach(element => {
        if (typeof element == "string" && element.includes(":")) {
          let temp = element.split(":");
          localID = temp[1];
          localName = temp[0];
        }
      });
      return {
        checkInDate: this.hotelOrderInfo.checkInDate, // 顶部-入住时间
        checkOutDate: this.hotelOrderInfo.departDate, // 顶部-离开时间
        city: this.hotelOrderInfo.city, // 顶部-城市
        locationId: localID, // 筛选-地区ID
        locationName: localName, // 筛选-地区名
        sort: this.sort, // 排序
        key: this.hotelOrderInfo.keyword, // 顶部-用户输入的关键字
        radius: 8000, // 搜索半径
        // 筛选-星级
        star:
          parseInt(
            this.ticket_filter_activeIds
              .filter(e => typeof e == "number")
              .join("")
          ) || 0,
        rangePrice: [
          // 筛选-价格
          this.priceRange[0],
          this.priceRange[1] == 1000 ? 99999 : this.priceRange[1]
        ].join("-"),
        longitude: this.position.longitude, // 顶部-关键字或城市的经纬度
        latitude: this.position.latitude,
        currentIndex: this.page,
        pageSize: this.pageSize,
        corp: "大唐"
      };
    },
    // 已选供应商
    selectedCorps: function() {
      let res = this.ticket_filter_activeIds.filter(e =>
        HotelServiceProvider.includes(e)
      );
      return 0 in res ? res : HotelServiceProvider;
    }
  },
  activated() {
    window.setTitle(this.hotelOrderInfo.city + "酒店信息");
    let dom = document.getElementsByClassName("v-virtual-scroll")[0];
    if (dom) dom.scrollTop++;
    this.showPop = false;
  },
  mounted() {
    window.setTitle(this.hotelOrderInfo.city + "酒店信息");
    // 百度地图API异步，所以用回调
    searchPosition(
      this.hotelOrderInfo.keyword,
      this.hotelOrderInfo.city,
      position => {
        this.position.longitude = position.lng; // 初始化经纬度
        this.position.latitude = position.lat; // 初始化经纬度
        this.fetchDistrict(); // 获取省份
        this.onRefresh(); // 拉取列表
      }
    );
  },
  data() {
    return {
      skeletonArr: SkeletonArr, // 骨架数据 - config.js
      sort: HotelDefaultSort, // 排序方式 - config.js
      renderType: HotelAsyncList, // 渲染方式 - config.js
      activenav: HotelAsyncList == "sync" ? "time" : "", // 底部四个按钮导航 变蓝
      listLoad: true, // 列表加载更多刷新动画
      currentlist: init(), // 分发ajax的对象
      position: null, // 坐标
      position: {}, // 经纬度
      priceRange: [100, 1000], // 价格区间
      marks: {
        0: {
          label: "￥0",
          style: {
            "font-size": "10px"
          }
        },
        1000: {
          label: "￥900以上",
          style: {
            "white-space": "nowrap",
            "font-size": "10px",
            transform: "translateX(-40px)"
          }
        }
      },
      page: 1, // 页码
      pageSize: HotelCorpPageSize, // 每页条数
      showList: [], // 显示的列表
      show_calendar: false, // 日历开关

      //筛选bar中的详细筛选
      ticket_filter_show: false,
      ticket_filter_activeIds: [], // 筛选框中已选值（地区以“：”做判断，其他选项请避免这个符号）
      ticket_filter_activeIndex: 0,

      district: [], // 行政区列表
      hideLoading: false, // 圈圈遮罩层
      showPop: false // 侧边框开关
    };
  },
  methods: {
    /******⭐⭐  列表控制👇  ⭐⭐******/

    // 按价格距离排序的排序方法
    sortSelf(type) {
      this.currentlist.sort({ sort: this.sort }, "hotel");
      this.showList = this.currentlist.list;
      let dom = document.getElementsByClassName("v-virtual-scroll")[0];
      if (dom) dom.scrollTop = 0; // 滚回顶部
      this.listLoad = false; // 更改”加载状态“
    },

    // 搜索
    fetchList() {
      this.finished = true;
      this.currentlist.update("hotel", this.query, this.selectedCorps); // 第三个参数用不到
    },

    // 加载更多
    async onLoad() {
      await this.fetchList(); // 拉数据
      this.page++; // 页码加1
    },

    // 重置列表
    onRefresh() {
      this.listLoad = true; // 列表状态切换为加载中
      this.showList = []; // 清空列表
      this.page = 1; // 重置到第一页
      this.currentlist.resetList(); // 重置源
      this.onLoad(); // 加载数据
    },

    /******⭐⭐   列表控制👆   ⭐⭐******/

    // 选择城市 转到选择城市页面 参数是vuex的actions名称
    chooseCity(vuxFunction = "hotel/setHotelOrderFrom") {
      this.hideLoading = true;
      setTimeout(() => {
        this.$emit("chooseCity", vuxFunction);
      }, 100);
    },

    // 日历选择日期
    calendarConfirm(date) {
      this.$store.dispatch("hotel/setHotelOrder", {
        checkInDate: date[0],
        departDate: date[1]
      });
      this.show_calendar = false;
      this.onRefresh();
    },

    // 拉取行政区列表
    fetchDistrict() {
      getHotelDistrict({
        corp: "大唐",
        cityCode: this.hotelOrderInfo.city
      }).then(response => {
        this.district = response.data;
      });
    }
  },

  filters: {
    showPriceRange(item = [0, 1000]) {
      if (item[1] == 1000) {
        if (item[0] == 0) return "全部价格";
        return "￥" + item[0] + "以上";
      }
      return `￥${item[0]}-${item[1]}`;
    }
  },

  /** ⭐⭐监听列表源变化⭐⭐
   * Tip: 目前提供同步渲染和异步渲染两种方案 在config.js中修改
   * listLoad 用来控制还在加载   currentlist.supplierNum 已返回供应商列表数量  selectedCorps当前检索的供应商数量
   * 数组绑定不好使，所以监听check值，每当数据返回check会自增。具体查看services/list.js
   */
  watch: {
    "currentlist.check": {
      handler: function() {
        // 异步加载
        if (this.renderType == "async") {
          if (this.currentlist.supplierNum >= this.selectedCorps.length)
            this.listLoad = false; // 所有请求完成更改”加载状态“
          this.showList = this.currentlist.list; // 有返回就往列表里塞
        }

        // 同步加载
        if (this.renderType == "sync") {
          if (
            this.currentlist.supplierNum >= this.selectedCorps.length &&
            this.listLoad
          ) {
            this.sortSelf(); // 所有请求完成调用排序
          }
        }
      }
    }
  }
};
</script>

<style lang="less" src="@/styles/searchCommon.less" scoped></style>
<style lang="less" scoped>
@import "../../../styles/theme.less";
.loading {
  position: absolute;
  top: 100px;
  width: 100%;
}
.tab_bar_filter_content {
  padding-top: 0;
}
.slider-title {
  margin: 0;
  padding-left: 1rem;
  text-align: left;
}
.slider-cont {
  padding: 0 1.5rem;
}
.deep-blue {
  color: @blue;
}
.top-city,
.top-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.top-date {
  padding: 0 0.4rem;
  transform: scale(0.9);
  font-size: @font-size-xs;
  line-height: 14px;
  border: 1px solid @gray-6;
  border-top: 0;
  border-bottom: 0;
}
.top-icon {
  padding: 2px 0;
}
.searchTrain {
  height: 100vh;
  overflow: hidden;
  .van-search__action {
    padding: 0 0.8rem;
  }
  .van-search__action:active {
    background-color: darken(@blue, 16%);
  }
}
.min-hei {
  min-height: calc(100vh - 108px);
  padding: 6px;
}
</style>
<style lang="less">
@import "../../../styles/theme.less";
.searchTrain {
  .van-field__control {
    margin-top: 2px;
  }
  .van-icon-clear {
    color: @gray-1;
  }
}
</style>